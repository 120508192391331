<template>
  <Layout>
    <page-layout
      v-if="flag"
      :page-titles="pageData.name"
      :bread-crumb-items="breadCrumbItems"
      :top-layout="true"
    >
      <template #top-content>
        <div class="d-flex detail_service">
          <div>
            <img class="image_detail" :src="pageData.logo" alt="image" />
          </div>
          <div class="service_detail">
            <p>
              <strong class="service_detail_name">{{ pageData.name }}</strong>
            </p>
            <p>{{ pageData.category_name }}</p>
          </div>
          <div class="user">
            <p class="fw-bold">
              利用中の顧客数:
              <span class="number_customer_detail">{{ totalCompanies }}</span>
            </p>
            <p class="fw-bold">
              利用中の従業員数：
              <span class="number_staff_detail">{{ totalUsers }}</span>
            </p>
          </div>
          <div class="status">
            <p class="status_detail fw-bold">
              ステータス:
              <span :class="checkClass(pageData)">{{ statusLabel }}</span>
            </p>
          </div>
          <div class="text-sm-end button_detail">
            <button
              type="button"
              class="btn me-2 button_edit_detail"
              @click="showEditDialog(pageData)"
            >
              編集
            </button>
          </div>
        </div>
      </template>
      <template #table-content>
        <detail-service-table />
      </template>
    </page-layout>
    <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
      <management-service-form
        :form-data.sync="formData"
        :trigger-validate="triggerValidate"
        :is-valid.sync="isValid"
        :include.sync="includeFields"
      />
    </c-modal>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import DetailServiceTable from '@/components/tables/detail-service-table'
import CModal from '../../components/commons/common-modal'
import ManagementServiceForm from '../../components/forms/management-service-form'
import { saasManagementService } from '@/services/saas-management-service'
import pick from 'lodash-es/pick'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    DetailServiceTable,
    Layout,
    PageLayout,
    CModal,
    ManagementServiceForm,
  },
  data() {
    return {
      flag: false,
      breadCrumbItems: [
        { text: 'SaaS管理', href: '/management-service' },
        { text: '', href: '#' },
      ],
      pageData: {},
      id: null,
      dataNumber: [],
      dialogId: 'ms-dialog',
      dialogTitle: '',
      formData: '',
      dialogTitles: {
        ADD: {
          id: 1,
          text: 'サービス新規登録',
        },
        EDIT: {
          id: 2,
          text: 'サービス情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      statusLabel: null,
      requiredEditKeys: ['id', 'name', 'category_id', 'status'],
      triggerValidate: false,
      isValid: false,
      onSave: () => {
        return {}
      },
      eidtId: ['id'],
      includeFields: [],
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['mt_status']),
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onSave,
        },
      ]
    },
    totalCompanies() {
      return this.dataNumber[0] ? this.dataNumber[0].total_companies : 0
    },
    totalUsers() {
      return this.dataNumber[0] ? this.dataNumber[0].total_users : 0
    },
  },
  async created() {
    this.showManualLoading()
    this.id = this.$route.query.id
    this.pageData = await saasManagementService.getDataDetail(this.id)
    this.dataNumber = await saasManagementService.getAppByCode(this.pageData.code)
    this.statusLabel = this.getStatusLabel()
    this.breadCrumbItems[1].text = this.pageData.name
    this.flag = true
    this.$nextTick(() => {
      this.hideManualLoading()
    })
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading']),
    showEditDialog(data) {
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.formData = { ...data }
      this.onSave = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.includeFields = []
      this.$bvModal.show(this.dialogId)
    },
    getStatusLabel() {
      const statusLabel = Object.values(this.mt_status).filter(
        (item) => item.id === this.pageData.status
      )[0]
      return statusLabel ? statusLabel.name : ''
    },
    checkClass(status) {
      return status.status === this.mt_status[1].id
        ? 'MTStatus MTStatus__active'
        : 'MTStatus MTStatus__inactive'
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, [...this.requiredEditKeys, ...this.includeFields])
      const id = pick(this.formData, this.eidtId)
      const { success, data } = await saasManagementService.editItem(id, formData)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.name ? MESSAGES.ADMIN_MT.ADM01 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.pageData = await saasManagementService.getDataDetail(this.id, true)
      this.statusLabel = this.getStatusLabel()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
  },
}
</script>
