<template>
  <div class="Table DSTable">
    <div class="Table__title">アカウント</div>
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
    </div>

    <div class="table-responsive">
      <b-table
        ref="ms-table"
        :items="items"
        :fields="fields"
        sort-by="id"
        thead-class="Table__header DSTable__header"
        tbody-tr-class="Table__row DSTable__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #cell(logo)="data">
          <img v-if="data.value" :src="data.value" alt="image" />
          <img v-else :src="defaultCompanyLogo" alt="default-image" />
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import SortIcon from '@/components/sort-icon'
import TablePaging from '@/components/commons/common-table-paging'
import { saasManagementService } from '@/services/saas-management-service'
import { stringToDateJapan } from '@/utils/date-utils'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'

export default {
  name: 'DetailServiceTable',
  components: {
    SearchInput,
    SortIcon,
    TablePaging,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: '顧客ID',
          sortable: true,
          tdClass: 'text-left align-middle',
          thClass: 'text-left align-middle',
        },
        { key: 'logo', label: 'ロゴ', tdClass: 'text-left', thClass: 'text-left align-middle' },
        {
          key: 'name',
          label: '会社名',
          sortable: true,
          tdClass: 'text-left align-middle',
          thClass: 'text-left align-middle',
        },
        {
          key: 'domain',
          label: 'ドメイン',
          sortable: true,
          tdClass: 'text-left align-middle',
          thClass: 'text-left align-middle',
        },
        {
          key: 'total_user',
          label: '利用中の従業員数',
          sortable: true,
          tdClass: 'text-left align-middle',
          thClass: 'text-left align-middle',
        },
        {
          key: 'created',
          label: '連携日時',
          sortable: true,
          tdClass: 'text-left align-middle',
          thClass: 'text-left align-middle',
        },
      ],
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      searchInput: '',
      id: null,
      total: 0,
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
    }
  },
  watch: {
    searchInput(newValue) {
      this.search(newValue)
    },
    perPage() {
      this.search(this.searchInput)
    },
    currentPage() {
      this.search(this.searchInput)
    },
  },
  async created() {
    this.id = this.$route.query.id
    await this.loadTable()
  },
  methods: {
    onEdit(rowData) {
      this.showEditDialog(rowData)
    },
    async loadTable() {
      const { items, total } = await saasManagementService.getDataTableDetail(this.id)
      this.items = items
      this.total = total
      this.searchInput = ''
      this.convertValue()
    },
    convertValue() {
      this.items.map((item) => {
        item.created = stringToDateJapan(item.created)
      })
    },
    async search(keySearch) {
      const { items, total } = await saasManagementService.getDataTableDetail(
        this.id,
        this.perPage,
        this.currentPage,
        keySearch
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
  },
}
</script>

<style lang="scss">
.DSTable {
  &__header th {
    &:nth-of-type(6) {
      width: 120px;
    }
    &:nth-of-type(5) {
      width: 250px;
    }
  }
}
</style>
